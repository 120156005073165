import { Button, Paper } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import QuadrilateralPuzzle from './QuadrilateralPuzzle'
import Highscore from './Highscore'

import styles from './Quadrilateral.module.css'
import TimerIcon from '@material-ui/icons/Timer'

const QuadrilateralGame = (props) => {
  const gameDuration = 300
  const strikeTime = 5
  const gameOverAfter = 4

  const [counter, setCounter] = useState(null)
  const counterInterval = useRef()
  const [gameState, setGameState] = useState('initial')
  const [puzzleKey, setPuzzleKey] = useState(0)
  const [score, setScore] = useState(0)
  const [history, setHistory] = useState([])
  const failcounter = useRef(0)

  useEffect(() => {
    if (gameState === 'running') {
      counterInterval.current = setInterval(
        () => setCounter((counter) => counter - 1),
        1000
      )
    } else {
      setCounter(null)
      clearInterval()
    }

    return () => clearInterval(counterInterval.current)
  }, [gameState])

  useEffect(() => {
    if (counter !== null && counter <= 0) gameEnd()
  }, [counter])

  function gameEnd() {
    setGameState('finished')
  }

  const restartGame = () => {
    newPuzzle()
    setScore(0)
    setHistory([])
    failcounter.current = 0
    setGameState('initial')
  }

  function newPuzzle() {
    setPuzzleKey((gameNumber) => gameNumber + 1)
  }

  function startGame() {
    setGameState('running')
    setCounter(gameDuration)
  }

  const handleSuccess = () => {
    setScore((score) => score + 1)
    setHistory((history) => [...history, { type: 'success', text: `✓` }])
  }

  const handleFail = () => {
    setHistory((history) => [...history, { type: 'fail', text: 'X' }])
    failcounter.current++
    if (failcounter.current >= gameOverAfter) {
      setGameState('over')
    }
  }

  const handleStrike = () => {
    setCounter((counter) => counter - strikeTime)
  }

  const restartButton = (text) => (
    <Button type="contained" onClick={restartGame}>
      {text}
    </Button>
  )
  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.history}>
          <div className={styles.score}>{score}</div>
          {history.map((el, i) => (
            <span key={i} className={styles[el.type]}>
              {el.text}
            </span>
          ))}{' '}
        </div>
        <div className={styles.counter}>
          {restartButton('Neustart')}
          <TimerIcon />
          {counter}
        </div>
      </div>
      <Paper className={styles.gamePaper}>
        {gameState === 'initial' && (
          <div className={styles.gameResult}>
            <h2>Welches Viereck bin ich?</h2>
            <p>
              Verwende die Hinweise, um das richtige Viereck zu erraten. <br />
              Fordere nicht zu viele Hinweise an, sonst wird dir
              Zeit abgezogen.
            </p>
            <p>Viermal falsch, und das Spiel ist vorbei. </p>
            <Button onClick={startGame} color='secondary' variant="contained">
              Spiel starten
            </Button>
            <Highscore />
          </div>
        )}

        {gameState === 'running' && (
          <QuadrilateralPuzzle
            key={puzzleKey}
            onSuccess={handleSuccess}
            onFail={handleFail}
            onContinue={newPuzzle}
            onStrike={handleStrike}
          />
        )}

        {gameState === 'finished' && (
          <div className={styles.gameResult}>
            <h2>Die Zeit ist um!</h2>
            <p>
              Dein Punktestand: <b>{score}</b>
            </p>
            <Highscore score={score} />
          </div>
        )}

        {gameState === 'over' && (
          <div className={styles.gameResult}>
            <h2>Game over!</h2> 🙁 Zu oft falsch. <br />
            {restartButton('Nochmal')}
          </div>
        )}
      </Paper>
    </>
  )
}

export default QuadrilateralGame
