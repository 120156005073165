import QuadrilateralGame from './components/Quadrilateral/QuadrilateralGame'
import './App.css'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff833a',
      main: '#e65100',
      dark: '#ac1900',
      contrastText: '#000',
    },
    secondary: {
      light: '#b4ffff',
      main: '#80deea',
      dark: '#4bacb8',
      contrastText: '#000',
    },
  },
})

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        {/*
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">News</Typography>
            <Button color="inherit">Login</Button>
          </Toolbar>
        </AppBar>*/}
        <div className="gameContainer">
          {/* <Problem /> */}
          <QuadrilateralGame />
        </div>
      </ThemeProvider>
    </>
  )
}

export default App
