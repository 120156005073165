import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: "AIzaSyAmwxYPh4TFcUPSITd2e4PgeOepBA_c2Sw",
  authDomain: "matherush.firebaseapp.com",
  projectId: "matherush",
  storageBucket: "matherush.appspot.com",
  messagingSenderId: "773017814339",
  appId: "1:773017814339:web:36ceed10cdbc9b8f9bdbf6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase.firestore()