import React, { useState, useEffect } from 'react'
import firestore from 'services/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore';


import styles from './Quadrilateral.module.css'

const collection = firestore.collection('highscore')
const query = collection.orderBy('score','desc').limit(10)

const Highscore = (props) => {
  const [highscore] = useCollectionData(query)
  const [inputName, setInputName] = useState('')
  const [canPost, setCanpost] = useState(true)

  const canGotoHiScore = props.score > 0 && canPost

  function handleSubmit(e) {
    e.preventDefault()
    console.log('post')
    collection.add({name: inputName, score: props.score})
    setCanpost(false)
  }

  return (
    <div className={styles.highscore}>
      {canGotoHiScore && (
        <div>
          Ergebnis eintragen:{' '}
          <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputName}
            placeholder='Name'
            onChange={(e) => setInputName(e.target.value)}
          />
          <input type="submit" />
          </form>
        </div>
      )}
      <table>
        <thead>
          <tr>
          <th>Name</th>
          <th>Punkte</th>
          </tr>
        </thead>
        <tbody>
          {highscore && highscore.map(el => <tr><td>{el.name}</td><td>{el.score}</td></tr>)}
        </tbody>
      </table>
    </div>
  )
}

export default Highscore
