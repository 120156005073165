const csv = `Ich habe vier gleich lange Seiten. ;;;;;;x;;x
Bei mir sind alle vier Seiten unterscheidlich lang. ;x;x;;;;;;
Zwei meiner Seiten sind gleich lang, die anderen beiden sind es nicht. ;;;x;;;;;
Ich habe genau eine Symmetrieachse. ;;;x;;x;;;
Bei mir sind die gegenüberliegenden Winkel gleich groß.;;;;x;;x;x;x
Bei mir ist nichts parallel.;x;;;;x;;;
Meine beiden Diagonalen sind gleich lang.;;;x;;;x;;x
Meine beiden Diagonalen schneiden sich im rechten Winkel.;;;;;x;x;;x
Ich besitze keine Symmetrien.;x;x;;;;;;
Ich bin punktsymmetrisch.;;;;x;;x;x;x
Ich besitze keinen rechten Winkel.;x;x;x;x;x;x;;
Ich besitze vier Symmetrieachsen.;;;;;;;;x
Dreht man mich um 180°, ändert sich nichts. ;;;;x;;x;x;x
Ich habe genau zwei Symmetrieachsen. ;;;;;;x;x;
Ich habe vier unterschiedliche Winkel. ;x;x;;;;;;
Meine beiden Diagonalen sind Symmetrieachsen. ;;;;;;x;;x
Genau eine meiner Diagonalen ist Symmetrieachse.;;;;;x;;;
Meine Diagonalen halbieren sich gegenseitig.;;;;;;x;x;x
Bei mir sind genau zwei Seiten parallel, die anderen nicht.;;x;x;;;;;
Ich bin achsensymmetrisch, aber nicht punktsymmetrisch.;;;x;;x;;;
Ich bin punktsymmetrisch, aber nicht achsensymmetrisch.;;;;x;;;;
Ich bin kein Rechteck.;x;x;x;x;x;x;;
Ich bin kein Parallelogramm.;x;x;x;;x;;;
Ich bin gleichzeitig Drachenviereck und Parallelogramm.;;;;;;x;;x
Ich bin gleichzeitig Raute und Rechteck. ;;;;;;;;x
Alle meine Mittelsenkrechten sind Symmetrieachsen. ;;;;;;;x;x
Keine meiner Diagonalen ist eine Symmetrieachse. ;x;x;x;x;;;x;
Ich habe keine spezielle Eigenschaft. ;x;;;;;;;`

export const hints = csv.split('\n').map((line) => {
  const fields = line.split(';')
  const isTrue = (input) => input === 'x'
  return {
    text: fields[0],
    allgemein: isTrue(fields[1]),
    trapez: isTrue(fields[2]),
    achsensymmetrischesTrapez: isTrue(fields[3]),
    parallelogramm: isTrue(fields[4]),
    drachenviereck: isTrue(fields[5]),
    raute: isTrue(fields[6]),
    rechteck: isTrue(fields[7]),
    quadrat: isTrue(fields[8]),
  }
})

export const quadrilaterals = [
  { name: 'allgemein', full: 'Allgemeines Viereck', gender:'n' },
  { name: 'trapez', full: 'Trapez', gender:'n' },
  { name: 'achsensymmetrischesTrapez', full: 'Achsensymmetrisches  Trapez',  gender:'n'},
  { name: 'parallelogramm', full: 'Parallelogramm', gender:'n'},
  { name: 'drachenviereck', full: 'Drachenviereck', gender:'n'},
  { name: 'raute', full: 'Raute', gender:'f'},
  { name: 'rechteck', full: 'Rechteck', gender:'n'},
  { name: 'quadrat', full: 'Quadrat', gender:'n'},
]
