import React, { useEffect, useState } from 'react'
import { hints, quadrilaterals } from './hints'
import { Button } from '@material-ui/core'

import styles from './Quadrilateral.module.css'
import bildViereck from './Viereck.png'

const QuadrilateralPuzzle = (props) => {
  const createPuzzle = () => {
    // Select one
    const selectedNumber = Math.floor(Math.random() * 8)
    const selectedQuadrilateral = quadrilaterals[selectedNumber]

    // Available hints
    const availableHints = hints.filter(
      (hint) => hint[selectedQuadrilateral.name]
    )
    // All are possible
    let possibleSolutions = quadrilaterals.map((q) => q.name)

    const hintsCollection = []

    const isConstraining = (hint) => {
      // First compute all the possibilities that would be still possible
      const stillPossibleSolutions = possibleSolutions.filter((s) => hint[s])
      return stillPossibleSolutions.length < possibleSolutions.length
    }

    while (possibleSolutions.length > 1) {
      // Fiter out all the hints that dont give any new information
      const constrainingHints = availableHints.filter(isConstraining)
      const selectedHint =
        constrainingHints[Math.floor(Math.random() * constrainingHints.length)]

      possibleSolutions = possibleSolutions.filter((s) => selectedHint[s])
      hintsCollection.push(selectedHint)
    }

    return {
      availableHints: availableHints,
      solution: selectedQuadrilateral,
      hintsCollection: hintsCollection,
    }
  }

  const inititalConversion = [
    { type: 'me', text: 'Los gehts: Hier kommt der erste Hinweis:' },
  ]

  const [puzzle] = useState(createPuzzle())
  const [conversation, setConversation] = useState(inititalConversion)
  const [hintNumber, setHintNumber] = useState(0)
  const [solved, setSolved] = useState(false)

  useEffect(() => {
    requestHint()
  }, [])

  const requestHint = () => {
    if (hintNumber < puzzle.hintsCollection.length) {
      setHintNumber(hintNumber + 1)
      setConversation((c) =>
        c.concat({
          type: 'me',
          text: puzzle.hintsCollection[hintNumber].text,
        })
      )
    } else {
      setConversation((c) =>
        c.concat({
          type: 'me',
          color: 'orange',
          text: 'Du hast schon ausreichend Hinweise. Das bedeutet Zeitstrafe!',
        })
      )
      props.onStrike()
    }
  }

  const giveAnswer = (answer) => {
    const addedAnswer = conversation.concat({
      type: 'you',
      text: `Dann bist du ein${answer.gender === 'f' ? 'e' : ''} ${
        answer.full
      }!`,
    })
    if (answer.name === puzzle.solution.name) {
      setConversation(
        addedAnswer.concat({ type: 'me', text: 'Richtig!', color: 'green' })
      )
      setSolved(true)
      props.onSuccess()
    } else {
      setConversation(
        addedAnswer.concat({
          type: 'me',
          text:
            'Falsch! Denke nochmal darüber nach, ob du alle Hinweise richtig verstanden hast.',
          color: 'red',
        })
      )
      props.onFail()
    }
  }

  return (
    <>
      <div className={styles.unknownImage}>
        <img src={bildViereck} alt="Bild eines unbekannten Vierecks" />{' '}
        <h2>Wo im Haus der Vierecke gehöre ich hin?</h2>
      </div>
      <div className={styles.hintsContainer}>
        {conversation.map((message, i) => (
          <div
            key={i}
            className={`${styles.bubble} ${styles[message.type]} ${
              styles[message.color]
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>

      {!solved ? (
        <>
          <div className={styles.additionalHint}>
            <Button onClick={requestHint} variant="contained" color="default">
              Ich brauche einen weiteren Hinweis.
            </Button>
          </div>
          <div className={styles.answerbuttons}>
            {quadrilaterals.map((q, i) => (
              <div>
                <Button
                  key={q.name}
                  onClick={() => giveAnswer(q)}
                  variant="contained"
                  color="secondary"
                >
                  {q.full}
                </Button>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={styles.continuebutton}>
          <Button variant='contained' style={{backgroundColor: '#88ff00'}} onClick={props.onContinue}>Nächste Aufgabe!</Button>
        </div>
      )}
    </>
  )
}

export default QuadrilateralPuzzle
